import React, { Component, Fragment, Suspense } from 'react';
import { Route, Switch, withRouter, } from 'react-router-dom';
import { withI18n } from "react-i18next";
import { I18nextProvider } from 'react-i18next';

import i18n from './i18n';

// import Button from './components/Button/Button';
import Contact from './pages/Contact/Contact';
import Layout from './components/Layout/Layout';
// import Loader from './components/Loader/Loader';
import Backdrop from './components/Backdrop/Backdrop';
import Toolbar from './components/Toolbar/Toolbar';
import MainNavigation from './components/Navigation/MainNavigation/MainNavigation';
import MobileNavigation from './components/Navigation/MobileNavigation/MobileNavigation';
import ErrorHandler from './components/ErrorHandler/ErrorHandler';
import LanguageList from './translation/LanguageList';
import Report from './pages/Report/AdReport';
import TermsOfUse from './pages/Terms/TermsOfUse';
import Payment from './pages/Payment/Payment';
import PaymentRegister from './pages/Payment/PaymentRegister';
import PrivacyPolicy from './pages/Terms/PrivacyPolicy';
import UserInfo from './pages/UserInfo/UserInfo';
import UserFiles from './pages/UserFiles/UserFiles';
// import NeedToLogin from './pages/NeedToLogin/NeedToLogin';
import NotPageFound from './pages/NotPageFound/NotPageFound';
// import DarkModeToggle from './components/DarkModeToggle/DarkModeToggle';

import AuthCheck from './components/Auth/AuthCheck';
import AppStorage from './util/appStorage';
import SetUserNameData from './components/Auth/SetUserNameData';
import UserSocketConnect from './components/SocketConnect/UserSocketConnect';

import { getAuthInfo } from './util/user';
// import { putBrowserHistory } from './util/history';


// import UserAdElements from './pages/UserAdElements/UserAdElements';
import SingleAdElement from './pages/UserAdElements/SingleAdElement/SingleAdElement';
import CreateAdElement from './pages/CreateAdElement/CreateAdElement';
import GetUserAdElements from './components/UI/getUserAdElements';
import AdminAdElements from './pages/Admin/AdminAdElements';
import AdminAdElement from './pages/Admin/AdminAdElement/AdminAdElement';
import AdminTopPage from './pages/Admin/AdminTopPage';
import HeadMetaTag from './components/UI/HeadMetaTag';
import AboutPage from './pages/AboutPage/AboutPage';

import './App.css';


// import * as firebase from "firebase/app";

// // Add the Firebase services that you want to use
// import "firebase/auth";
// import "firebase/firestore";

// // TODO: Replace the following with your app's Firebase project configuration
// // TODO: Replace the following with your app's Firebase project configuration
// var firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
//   authDomain: process.env.REACT_APP_FIREBASE_PROJECTID + ".firebaseapp.com",
//   databaseURL: "https://" + process.env.REACT_APP_FIREBASE_PROJECTID + ".firebaseio.com",
//   projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
//   storageBucket: process.env.REACT_APP_FIREBASE_PROJECTID + ".appspot.com",
//   messagingSenderId: "327377228340",
//   appId: "1:327377228340:web:b9e2ff48f5d5c02bb13061",
//   measurementId: "G-6BYDDFRPNX"
// };

// // Initialize Firebase
// firebase.initializeApp(firebaseConfig);


//// dev urls
// export const BASE_URL = process.env.REACT_APP_DEV_BASE_URL;
// export const GQL_URL = process.env.REACT_APP_DEV_GQL_URL;
// export const SOCKET_URL = process.env.REACT_APP_DEV_SOCKET_URL;
// export const SOCKET_GROUP_URL = process.env.REACT_APP_DEV_SOCKET_GROUP_URL;
// export const SOCKET_SURL = process.env.REACT_APP_DEV_SOCKET_SURL;
// export const SOCKET_GROUP_SURL = process.env.REACT_APP_DEV_SOCKET_GROUP_SURL;
// export const PUSH_URL = process.env.REACT_APP_DEV_PUSH_URL;
// export const authPageLink = process.env.REACT_APP_DEV_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const authSignupPageLink = process.env.REACT_APP_DEV_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const USERACCOUNTPAGE_URL = 'http://localhost:8506';


//// test urls
// export const BASE_URL = process.env.REACT_APP_TEST_BASE_URL;
// export const GQL_URL = process.env.REACT_APP_GQL_URL;
// export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
// export const SOCKET_GROUP_URL = process.env.REACT_APP_SOCKET_GROUP_URL;
// export const SOCKET_SURL = process.env.REACT_APP_SOCKET_SURL;
// export const SOCKET_GROUP_SURL = process.env.REACT_APP_SOCKET_GROUP_SURL;
// export const PUSH_URL = process.env.REACT_APP_PUSH_URL;
// export const authPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const authSignupPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
// export const USERACCOUNTPAGE_URL = 'https://casauthsso-userpage-test.web.app';


//// do urls deploy  Don't Forget update servicewoker file for build
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const GQL_URL = process.env.REACT_APP_GQL_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const SOCKET_GROUP_URL = process.env.REACT_APP_SOCKET_GROUP_URL;
export const SOCKET_SURL = process.env.REACT_APP_SOCKET_SURL;
export const SOCKET_GROUP_SURL = process.env.REACT_APP_SOCKET_GROUP_SURL;
export const PUSH_URL = process.env.REACT_APP_PUSH_URL;
export const authPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/login?fromUrl=${encodeURIComponent(window.location.origin)}`;
export const authSignupPageLink = process.env.REACT_APP_AUTHPAGE_URL + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;
export const USERACCOUNTPAGE_URL = 'https://useraccount.spaceeight.net';






  //// PWA-cource

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/service-worker.js')
      // .register('/sw.js')
      .then(() => {
        console.log('Service-worker registered!');
      }).catch((err) => {

        console.log('service-worker register error', err);
      });
  }

  //// end of PWA-course


  const aps = new AppStorage();
  console.log('appStorage', aps);


class App extends Component {
  state = {
    showBackdrop: false,
    showMobileNav: false,
    isAuth: false,
    token: null,
    userId: null,
    authLoading: false,
    error: null,
    name: '',
    imageUrl: '',
    isEmailVerified: false,
    sendVerifyMailMessage: '',
    firebaseUser: '',
    userUpdateAction: 0,
    darkMode: false,
    store: null,
  };

  componentDidMount() {
    console.log('app.js-props', this.props);
    // this.getAuthInfo();

    getAuthInfo()
      .then(result => {
        console.log(result);

        const token = localStorage.getItem('token');
        const expiryDate = localStorage.getItem('expiryDate');
        if (!token || !expiryDate) {
          return;
        }
        if (new Date(expiryDate) <= new Date()) {
          this.logoutHandler2();
          return;
        }
        const userId = localStorage.getItem('userId');
        const name = localStorage.getItem('name');
        const remainingMilliseconds =
          new Date(expiryDate).getTime() - new Date().getTime();
        this.setState({
          isAuth: true,
          token: token,
          userId: userId,
          name: name,
        });
        this.setAutoLogout(remainingMilliseconds);
    
        // //firebase user
        // firebase.auth().onAuthStateChanged((user) => {
        //   if (user) {
        //     // User is signed in.
        //     console.log('fb user', user);
        //     this.setState({
        //       firebaseUser: user
        //     })
        //   } else {
        //     // User is signed out.
        //     console.log('no fb user')
        //   }
        // });
    
        // this.props.history.push('/');

      })
      .catch(err => {
        console.log(err);

        this.logoutHandler2();
        
        err.message = 'Login failed ...';

        this.setState({
            // isAuth: false,
            authLoading: false,
            error: err
        });
      })



    this.props.history.listen((location, action) => {
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`,
        location
      );
      console.log(`The last navigation action was ${action}`);
    });


    
    window.addEventListener('beforeinstallprompt', (event) => {
      console.log('beforeinstallprompt fired in app');
      // event.preventDefault();
      // deferredPrompt = event;
      // return false;
    });

 

    
    //// set language if user selected language in localStorage
    const lsUserSelectLng = localStorage.getItem('userSelectLng');
    if (lsUserSelectLng) {
      // console.log(lsUserSelectLng);
      i18n.changeLanguage(lsUserSelectLng);
    }

  }

 

  mobileNavHandler = isOpen => {
    this.setState({ showMobileNav: isOpen, showBackdrop: isOpen });
  };

  backdropClickHandler = () => {
    this.setState({ showBackdrop: false, showMobileNav: false, error: null });
  };

  userInfoAction = (userObj) => {
    this.setState({
      name: userObj.name,
      userUpdateAction: this.state.userUpdateAction + 1,
    });
    localStorage.setItem('name', userObj.name);
  }

  setDarkMode = (darkModeInput) => {
    this.setState({ darkMode: darkModeInput },
      () => console.log(this.state.darkMode))
  }

  setStore = (storeData) => {
    this.setState({ store: storeData });
  }


  logoutHandler2 = () => {

    this.setState({ isAuth: false, token: null, name: '' });
    localStorage.removeItem('token');
    localStorage.removeItem('expiryDate');
    localStorage.removeItem('userId');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('userLocation');

    localStorage.removeItem('casTGT');
    localStorage.removeItem('tokenForCasTGT');
    localStorage.removeItem('TGTexp');

    localStorage.removeItem('customerEmail');
    localStorage.removeItem('customerId');
    this.props.history.push('/');
    window.location.reload();
  };



  setAutoLogout = milliseconds => {
    setTimeout(() => {
      this.logoutHandler2();
    }, milliseconds);
  };



  // ResetPasswordHandler = () => {
  //   console.log('in reset passwordHandler');
  // }

  // getNewPasswordTokenUser = () => {

  // }

  errorHandler = () => {
    this.setState({ error: null });
  };

  render() {
    // console.log('App.js this.state', this.state);
    let isAdmin = false;

    if (this.state.store && this.state.store.userData && this.state.store.userData.status && this.state.store.userData.status === 'admin') {
      isAdmin = true;
    }

    let routes = (
      <Switch>
        {/* <Route
          path="/feed/posts"
          exact
          render={props => (
            <FeedPage {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
          )}
        />
        <Route
            path="/feed/userposts/:userId"
            // exact
            render={props => (
              <FeedPage {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} darkMode={this.state.darkMode}/>
            )}
        />
        <Route
          path="/feed/:postId"
          render={props => (
            <SinglePostPage {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
          )}
        /> */}
        <Route
            path="/termsofuse"
            render={props => (
              <TermsOfUse {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
            )}
          />
          <Route
            path="/privacypolicy"
            render={props => (
              <PrivacyPolicy {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
            )}
        />
        <Route
          path="/contact"
          // exact
          render={props => (
            <Contact {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
          )}
        />
        <Route
          path="/about-page"
          // exact
          render={props => (
            <AboutPage {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
          )}
        />
        <Route
          path="/"
          // exact
          render={props => (
            <NotPageFound {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
          )}
        />
        {/* <Redirect to="/login" /> */}
      </Switch>
    );
    if (this.state.isAuth) {
      routes = (
        <Switch>
        <Route
            path="/ad-element/:adElementId"
            render={props => (
              <SingleAdElement {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
            )}
          />
          <Route
            path="/create-ad-element"
            exact
            render={props => (
              <CreateAdElement {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} darkMode={this.state.darkMode}/>
            )}
          />
          <Route
            path="/userinfo"
            exact
            render={props => (
              <UserInfo
                {...props}
                userId={this.state.userId}
                token={this.state.token}
                isAuth={this.state.isAuth}
                userInfoAction={this.userInfoAction}
              />
            )}
          />
          <Route
            path="/termsofuse"
            render={props => (
              <TermsOfUse {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
            )}
          />
          <Route
            path="/payment"
            render={props => (
              <Payment {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
            )}
          />
          <Route
            path="/report"
            render={props => (
              <Report {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
            )}
          />
          <Route
            path="/privacypolicy"
            render={props => (
              <PrivacyPolicy {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
            )}
          />
          <Route
            path="/contact"
            // exact
            render={props => (
              <Contact {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
            )}
          />
          <Route
            path="/user-files"
            // exact
            render={props => (
              <UserFiles {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
            )}
          />
          <Route
            path="/about-page"
            // exact
            render={props => (
              <AboutPage {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
            )}
          />

          {isAdmin && (
            <span>
              <Route
                path="/admin/top-page"
                // exact
                render={props => (
                  <AdminTopPage {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
                )}
              />
    
              <Route
                path="/admin/ad-elements"
                // exact
                render={props => (
                  <AdminAdElements {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
                )}
              />
              <Route
                path="/admin/ad-element/:adElementId"
                // exact
                render={props => (
                  <AdminAdElement {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
                )}
              />
            </span>
          )}

          {/* <Route
            path="/"
            exact
            render={props => (
              // <FeedPage {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
              <Redirect to="/feed/posts" />
            )}
          /> */}
          <Route
            path="/"
            // exact
            render={props => (
              <NotPageFound {...props} userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
              // <FeedPage userId={this.state.userId} token={this.state.token} isAuth={this.state.isAuth} />
            )}
          />
          {/* <Redirect to="/feed/post/" /> */}
        </Switch>
      );
    }
    return (
      <Fragment>

        <Suspense fallback={null}>
          <I18nextProvider i18n={i18n}>

            {this.state.showBackdrop && (
              <Backdrop onClick={this.backdropClickHandler} />
            )}
            <ErrorHandler error={this.state.error} onHandle={this.errorHandler} />
            <Layout
              header={
                <Toolbar>
                  <MainNavigation
                    onOpenMobileNav={this.mobileNavHandler.bind(this, true)}
                    onLogout={this.logoutHandler2}
                    isAuth={this.state.isAuth}
                    token={this.state.token}
                    name={this.state.name}
                    userUpdateAction={this.state.userUpdateAction}
                    history={this.props.history}
                  />
                </Toolbar>
              }
              mobileNav={
                <MobileNavigation
                  open={this.state.showMobileNav}
                  mobile
                  onChooseItem={this.mobileNavHandler.bind(this, false)}
                  onLogout={this.logoutHandler2}
                  isAuth={this.state.isAuth}
                  token={this.state.token}
                  name={this.state.name}
                  userUpdateAction={this.state.userUpdateAction}
                />
              }
              setStore={this.setStore}
            />

            <div className="AppStretch AppContainer"> 


            
            {/* <a href={authPageLink}>auth-link-button</a>
            <br/>
            <a href={authSignupPageLink}>auth-signup-link-button</a> */}

            {isAdmin && (
              <div>
                admin-user
                {/* <Link to="/user-files">user-files-page</Link> */}
              </div>
            )}


              <div>
                {routes}
              </div>

                <div style={{display: 'none'}}>
                  {/* <DarkModeToggle 
                    setDarkMode={this.setDarkMode}
                /> */}

                {/* set languageNameList in store */}
                <div style={{display: 'none'}}>
                  <LanguageList />
                </div>

                <AuthCheck 
                  isAuth={this.state.isAuth}
                  logoutHandler2={this.logoutHandler2}
                />

                <HeadMetaTag />

                </div>
                  <div></div>
                  <div></div>

            </div>
            
            {this.state.isAuth && (
              <div>
                <GetUserAdElements 
                  isAuth={this.state.isAuth} 
                  history={this.props.history}
                />
                <PaymentRegister />
                <SetUserNameData isAuth={this.state.isAuth} />
                <UserSocketConnect />
              </div>
            )}


          </I18nextProvider>
        </Suspense>

      </Fragment>
    );
  }
}

export default withI18n()(withRouter(App));
